import React, { useEffect, useState } from "react";
import { useQueryParam, StringParam } from "use-query-params";
import { Loading } from "../../../components";
import { GATEWAY_NAME } from "../../../core/constants";

import { Scene, InfoCard, InstallPaymentMethod } from "../../../layout/shopify";

const Index = () => {
  const [gatewayName, updateGatewayName] = useQueryParam(
    "gatewayName",
    StringParam
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const validateGateway = (gatewayName) => {
      if (!Object.values(GATEWAY_NAME).includes(gatewayName)) {
        updateGatewayName(null);
      }
    };
    validateGateway(gatewayName);
    setLoading(false);
  }, [gatewayName, loading, updateGatewayName]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Scene>
      <InfoCard
        title="Accept payments with Latitude"
        description="Simply enter your Shopify URL to begin installation."
        gatewayName={gatewayName}
      >
        <InstallPaymentMethod gatewayName={gatewayName} />
      </InfoCard>
    </Scene>
  );
};

export default Index;
